
import { Component, Inject, Vue } from "vue-property-decorator";
import QuickPurchasePageTitle from "@/components/landingPages/quickPurchase/QuickPurchasePageTitle.vue";

@Component({
  name: "QuickPurchaseUpgradeInProgress",
  components: { QuickPurchasePageTitle },
})
export default class QuickPurchaseUpgradeInProgress extends Vue {
  @Inject() readonly quickPurchaseBus!: Vue;

  toPlatform() {
    this.quickPurchaseBus.$emit("shouldGoToPlatform");
  }

  mounted() {
    this.quickPurchaseBus.$emit("shouldDisplayHostedPlanCta", false);
  }
}
